@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.molo-card {
  display: flex;
  flex-direction: column-reverse;
  padding: 32px;
  column-gap: 2em;
  overflow: hidden;
}

.molo-card.molo-card-reverse {
  padding: 32px 16px;
}

.molo-card-box {
  display: flex;
  margin-top: fn.spacing(6.5);
  flex: 1;
  z-index: 2;
}

.molo-card-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.molo-card-text-title {
  color: #30f;
  letter-spacing: var.$eyebrow-letter-spacing;
}

.molo-card-text-title-black {
  color: #1d252f;
  letter-spacing: var.$eyebrow-letter-spacing;
}

.molo-card-text-header {
  margin-top: 16px;
}

.molo-card-text-content {
  margin-top: 16px;
  margin-bottom: 32px;
}

.molo-card-text-link {
  color: #30f;
  display: flex;
  margin-top: 24px;
}

.link-arrow-right {
  margin-left: 12px;
}

.molo-card-image {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;
}

.molo-card-image-bg {
  position: relative;
}

.molo-card-image-bg span {
  display: block !important;
  height: 280px !important;
  width: 220px !important;
  margin-right: -150px !important;
}

.molo-card-image-bg span img {
  border-radius: 4px;
  min-width: 100%;
  min-height: 100%;
}

.molo-card-image-top {
  position: absolute;
  bottom: -20px;
  left: 150px;
  box-shadow: 0 8.6px 69.5px -4.3px rgb(0 0 0 / 13%), 0 13px 19.5px -4.3px rgb(16 24 40 / 6%);
  border: 0.8px solid var.$color-neutral-75;
  border-radius: 8px;
  overflow: hidden;
}

.molo-card-image-top span {
  width: 170px !important;
  height: auto !important;
  position: initial !important;
  display: block !important;
  overflow: inherit !important;
}

.molo-card-image-reverse .molo-card-image-bg span {
  box-shadow: 0 8.6px 69.5px -4.3px rgb(0 0 0 / 13%), 0 13px 19.5px -4.3px rgb(16 24 40 / 6%);
  border-radius: 8px;
  background: var.$color-neutral-25 !important;
  width: 194px !important;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  height: 460px;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
}

.molo-card-image-top span img {
  max-height: inherit !important;
  max-width: inherit !important;
  width: 170px !important;
  height: auto !important;
  position: initial !important;
  min-width: 100%;
  min-height: 100%;
}

.molo-section .molo-card-image-bg span img {
  padding: 10px !important;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
}

.molo-card-image::before {
  content: '';
  width: 220px;
  height: 90%;
  position: absolute;
  background: linear-gradient(180deg, rgb(25 56 219 / 15%) 24.21%, rgb(211 86 111 / 15%) 100%);
  filter: blur(75px);
  transform: rotate(-1.29deg);
  bottom: 0;
  left: fn.spacing(2.5);
  top: 13%;
}

.molo-card-image-reverse.molo-card-image::before {
  background: linear-gradient(
    223.13deg,
    rgb(25 56 219 / 15%) 0.62%,
    rgb(33 161 255 / 15%) 54.65%,
    rgb(254 206 47 / 15%) 69.78%
  );
}

.molo-card-image[data-align-left='true'] .molo-card-image::before {
  background: linear-gradient(180deg, rgb(25 56 219 / 30%) 24.21%, rgb(211 86 111 / 30%) 100%);
}

.molo-card-image[data-align-left='true'] .molo-card-box {
  margin-top: 32px;
}

.molo-card-image[data-align-left='true'] .molo-card-image .molo-card-image-top {
  box-shadow: none;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 0;
  border-radius: 0;
  overflow: inherit;
}

.molo-card-image[data-align-left='true'] .molo-card-image .molo-card-image-top span img {
  box-shadow: 0 8.6px 69.5px -4.3px rgb(0 0 0 / 13%), 0 13px 19.5px -4.3px rgb(16 24 40 / 6%);
  min-height: inherit !important;
  border: 0.8px solid var.$color-neutral-75 !important;
  border-radius: 8px;
  overflow: hidden;
}

//Tablet styles
@media only screen and (min-width: 768px) {
  .molo-card {
    min-height: 600px;
    padding: 32px 64px;
    flex-direction: row;
  }

  .molo-card.molo-card-reverse {
    padding: 32px 64px;
  }

  .molo-card-box {
    margin-top: 0;
  }

  .molo-card-text {
    max-width: 462px;
  }

  .molo-card-text-header {
    margin-top: fn.spacing(3.5);
  }

  .molo-card-text-content {
    margin-top: fn.spacing(3.5);
  }

  .molo-card-image-bg span {
    height: 320px !important;
    width: 250px !important;
    margin-right: 120px !important;
  }

  .molo-card-image-reverse .molo-card-image-bg span {
    width: 228px !important;
  }

  .molo-card-image-top {
    bottom: 70px;
    left: inherit;
    right: 15px;
  }

  .molo-card-image::before {
    width: 250px;
  }

  .molo-card[data-align-left='true'] {
    flex-direction: row-reverse;
    background: var.$color-neutral-25;
  }

  .molo-card[data-align-left='true'] .molo-card-image {
    align-items: flex-start;
  }

  .molo-card[data-align-left='true'] .molo-card-image-top {
    left: 220px;
    box-shadow: none;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 0;
    right: 15px;
    position: absolute;
    overflow: hidden;
    border-radius: 4px;
  }
}

//Desktop styles
@media only screen and (min-width: 1024px) {
  .molo-card-image::before {
    width: 360px;
  }

  .molo-card-image-bg span {
    height: 460px !important;
    width: 360px !important;
    margin-right: 150px !important;
  }

  .molo-card-image-top {
    bottom: -10px;
  }

  .molo-card[data-align-left='true'] .molo-card-image-reverse .molo-card-image-top {
    left: 0;
    top: 230px;
    width: 250px;
  }

  .molo-card[data-align-left='true'] .molo-card-image-reverse .molo-card-image-bg {
    left: 230px;
  }

  .molo-card-image-top span {
    width: 270px !important;
  }

  .molo-card-image-top span img {
    width: 250px !important;
    display: block;
  }

  .molo-card-image-reverse span {
    width: 250px !important;
  }

  .molo-card-image-reverse .molo-card-image-bg span {
    margin-right: 220px !important;
    width: 324px !important;
  }
}

@media only screen and (max-width: 768px) {
  .molo-card-image {
    display: block !important;
  }

  .molo-card-image-reverse .molo-card-image-top {
    left: 164px;
  }

  .molo-card[data-align-left='true'] .molo-card-image-reverse .molo-card-image-bg {
    left: 120px;
  }

  .molo-card[data-align-left='true'] .molo-card-image-reverse .molo-card-image-top {
    left: 0;
    top: 115px;
  }
}
